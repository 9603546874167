import React from 'react'
import { Link } from "gatsby"
import Img from "gatsby-image"


class HeroSplitWidth extends React.Component {

  

  render() {
    const { title, heroImg, herobgColor, publicURL, imgLink, imgAuthor,  excerpt, category, previous, next } = this.props
    var str = excerpt;
    var newexcerpt = str.replace(/\..*$/i, '.');

    return (
        <div>
            <div className="w-100 mw9 center cf relative">

                    <div className="w-100 pv3 ph4 w-100 bg-white-30 bg-animate hover-bg-light-gray o-80">
                        <div className="mw9 center">
                            <div className="flex">
                                    <div className="w-50 pr2">
                                        {previous && (
                                            <Link className="f7 black tracked fl tl" to={previous.fields.slug} rel="prev">
                                            ← {previous.frontmatter.title}
                                            </Link>
                                        )}
                                    </div>
                                    <div className="w-50 pl2">
                                        {next && (
                                            <Link className="f7 black tracked fr tr" to={next.fields.slug} rel="next">
                                            {next.frontmatter.title} →
                                            </Link>
                                        )}
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-100 cf">
                        <div className="fl w-100 w-100-m w-40-l vh-50 h-100-m vh-60-l flex items-center ph0 ph4-ns bg-theme-25">
                            <article className="pt4 pt5-m ph4 ph5-l">
                                <div  className="f7  tracked-mega mb0 ttu white"><div className="square-0-5 bg-gold mr2 dib "></div>{category}</div>
                                <div className="f3 f2-ns white fw4 mt2 mb3 lh-solid tracked-sm">{title}</div>
                                <div  className="f6 fw4 white tracked mb0 lh-copy">{newexcerpt}</div>
                            </article>
                        </div>
                        <div className={"fl w-100 w-100-m w-60-l vh-40 vh-60-ns cf " + herobgColor}>
                        { heroImg  ?    
                                        <div className="flex flex-hv-center vh-40 vh-60-ns">
                                          <Img className="w-90" sizes={heroImg} />
                                        </div>
                                        :
                                        <div className="flex flex-hv-center vh-40 vh-60-ns">
                                            <img className="w-100 w-80-ns cf" src={publicURL} alt="..." />
                                        </div>
                                      } 
                        </div>
                    </div>

            </div>

           

            { imgAuthor  ?  
                <div className="w-100 cf ">
                    <div id="imgCaption" className="w-100 center f6  ph4 pt3 ">
                    <a className="link tracked-sm theme-75 dim pr2 pointer avenir fr-ns" href={imgLink} >
                        {imgAuthor}
                    </a> 
                    </div>
                </div>
                :
                    ''
                 } 

        </div>
    )
  }
}


export default HeroSplitWidth
