import React from 'react'
import { Link } from "gatsby"
import Img from "gatsby-image"

class HeroFullWidth extends React.Component {

   

  render() {

    const { title, heroImg, imgLink, publicURL, imgAuthor, category, previous, next } = this.props

   


    return (

          <div className="w-100 cf db">
                <div className="w-100 mw9 center relative cf">
                        <div className="pv3 ph4 w-100 bg-white-30 bg-animate hover-bg-light-gray o-80">
                            <div className="mw9 center">
                                <div className="flex">
                                        <div className="w-50">
                                            {previous && (
                                                <Link className="f7 black tracked fl tl" to={previous.fields.slug} rel="prev">
                                                ← {previous.frontmatter.title}
                                                </Link>
                                            )}
                                        </div>
                                        <div className="w-50">
                                            {next && (
                                                <Link className="f7 black tracked fr tr" to={next.fields.slug} rel="next">
                                                {next.frontmatter.title} →
                                                </Link>
                                            )}
                                        </div>
                                </div>
                            </div>
                        </div>

                       
                        

                                      { heroImg  ?   <Img className="vh-40 vh-50-m vh-60-l" sizes={heroImg} />
                                      :
                                      <img className="w-100 vh-60 cf" src={publicURL} alt="..." />
                                      } 
                       

                        <div className="w-100 bg-theme-25 cf w-100 pa4 pa5-ns">
                          <div className="mw9 center">
                                <div className="f7 white tracked-mega mb0 ttu"><div className="square-0-5 bg-gold mr2 dib "></div> {category}</div>
                                <div className="f3 f2-ns white fw4 mt2 mb3 lh-solid tracked-sm">{title}</div>
                          </div>
                        </div> 

                </div>


                { imgAuthor  ?   <div className="w-100 cf ">
                    <div id="imgCaption" className="w-100 center f6  ph4 pt3">
                    <a className="link tracked-sm theme-75 dim pr2 pointer avenir fr-ns" href={imgLink} >
                        {imgAuthor}
                    </a> 
                    </div>
                </div>
                :
                    ''
                 } 


                


          </div>


    )
  }
}

export default HeroFullWidth
