import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import HeroFullWidth from "../components/HeroFullWidth"
import HeroFullWidthTop from "../components/HeroFullWidthTop"
import HeroSplitWidth from "../components/HeroSplitWidth"
import Img from "gatsby-image"
import arrowLeft from '../../content/assets/arrow-left.svg'
import arrowRight from '../../content/assets/arrow-right.svg'

class ArticlePostTemplate extends React.Component {



  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
  
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />

          <div className="flex flex-column animated fast fadeIn">


                   {post.frontmatter.heroStyle === 'fullwidth' ? 

                      <HeroFullWidth 
                      heroImg= { post.frontmatter.cover.childImageSharp ? post.frontmatter.cover.childImageSharp.sizes : ''}
                      srcset= { post.frontmatter.cover.childImageSharp ? post.frontmatter.cover.childImageSharp.sizes.srcSet: ''}
                      publicURL = {post.frontmatter.cover.publicURL}
                      title= {post.frontmatter.title}
                      category= {post.frontmatter.category} 
                      imgLink = {post.frontmatter.imgLink}
                      imgAuthor = {post.frontmatter.imgAuthor}
                      previous = {previous}
                      next = {next}
                      />

                     :

                     post.frontmatter.heroStyle === 'fullwidthtop' ? 
                    
                     <HeroFullWidthTop 
                     heroImg= { post.frontmatter.cover.childImageSharp ? post.frontmatter.cover.childImageSharp.sizes : ''}
                     srcset= { post.frontmatter.cover.childImageSharp ? post.frontmatter.cover.childImageSharp.sizes.srcSet: ''}
                     publicURL = {post.frontmatter.cover.publicURL}
                     title= {post.frontmatter.title}
                     category= {post.frontmatter.category} 
                     imgLink = {post.frontmatter.imgLink}
                     imgAuthor = {post.frontmatter.imgAuthor}
                     previous = {previous}
                     next = {next}
                     />
                    :

                    <HeroSplitWidth 
                    heroImg= { post.frontmatter.cover.childImageSharp ? post.frontmatter.cover.childImageSharp.sizes : ''}
                    srcset= { post.frontmatter.cover.childImageSharp ? post.frontmatter.cover.childImageSharp.sizes.srcSet: ''}
                    publicURL = {post.frontmatter.cover.publicURL}
                    herobgColor = {post.frontmatter.herobgColor}
                    title= {post.frontmatter.title}
                    category= {post.frontmatter.category} 
                    imgLink = {post.frontmatter.imgLink}
                    imgAuthor = {post.frontmatter.imgAuthor}
                    excerpt= {post.excerpt} 
                    previous = {previous}
                    next = {next}
                    />
                    
                    }
                   


            


            <div className="w-100  pt4">
           
                   
                  {/* Page article content*/}
                  <article className="pb5">
                      <div dangerouslySetInnerHTML={{ __html: post.html }}/>
                  </article>

        
                  {/* Previous and Next pagination at the bottom of the page*/}
                  <div className="w-100 bg-light-gray cf">
                  <div className="w-100 mw7 center mb5 cf">
                    
                      
                          {previous && (

                            <Link className="fl w-100 w-50-ns f6  theme-100 pointer tracked ph0 ph3 grow" to={previous.fields.slug} rel="prev">
                                <article className="bg-white center mw5 ba b--black-10 mv4 br3 overflow-hidden shadow-5 fr-ns ">
                                  { previous.frontmatter.cover.childImageSharp  ?   
                                  <div className=" max-h-200">
                                    <Img className="w-100 nb1" sizes={previous.frontmatter.cover.childImageSharp.sizes} />
                                  </div>
                                  :
                                  <div className=" max-h-200 bg-theme-100">
                                    <img className="w-100 nb1" src={previous.frontmatter.cover.publicURL} alt="..." />
                                  </div>
                                  } 
                                  <div className="pa3">
                                    <div className="f6 mb2"> {previous.frontmatter.title}</div>
                                    <img className="square-1" src={arrowLeft} alt="arrow-left"/>
                                  </div>
                                </article>
                            </Link>

                          )}
                     
                          {next && (
                             <Link className="fl w-100 w-50-ns f6  theme-100 pointer tracked ph0 ph3 grow" to={next.fields.slug} rel="prev">
                              <article className="bg-white center mw5 ba b--black-10 mv4 br3 overflow-hidden shadow-5 fl-ns">
                                  { next.frontmatter.cover.childImageSharp  ?   
                                  <div className=" max-h-200">
                                    <Img className="w-100 nb1" sizes={next.frontmatter.cover.childImageSharp.sizes} />
                                  </div>
                                  :
                                  <div className=" max-h-200 bg-theme-100">
                                    <img className="w-100 nb1" src={next.frontmatter.cover.publicURL} alt="..." />
                                  </div>
                                  } 
                                  <div className="pa3">
                                    <div className="f6 mb2"> {next.frontmatter.title}</div>
                                    <img className="square-1" src={arrowRight} alt="arrow-right"/>
                                  </div>
                              </article>
                             </Link>
                          )}

                    
                    </div>

                    </div>
              </div>
          </div>

      </Layout>
    )
  }
}

export default ArticlePostTemplate

export const pageQuery = graphql`
  query ArticlePostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 100)
      html
      frontmatter {
        title
        category
        imgLink
        imgAuthor
        order
        cover {
                publicURL
                childImageSharp{
                  sizes(maxWidth: 1280, quality: 80) {
                   ...GatsbyImageSharpSizes
                  }
                }
            }
        heroStyle
        herobgColor
      }
    }
  }
`
